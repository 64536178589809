<template>
	<WemotooHeader />

	<div class="bg-neutral">
		<div class="wrapper">
			<div v-if="props.showBackButton" class="flex justify-start h-[100px]">
				<button class="button-center" @click="$router.back()">
					<UIcon name="i-heroicons-chevron-left" class="w-[1.25rem] h-[1.25rem] text-black text-center" />
					<p class="text-[14px] font-bold font-jakarta">Back</p>
				</button>
			</div>
			<div class="container-content no-scrollbar">
				<!-- sm:w-auto -->
				<slot name="content" />
			</div>
		</div>
		<div v-if="props.showBackButton" class="sticky-div">
			<EventTicketPrice :disabled-button="props.disabledButton" :title="buttonTitle" @submit="props.onSubmit" />
		</div>

		<WemotooFooter />
	</div>
</template>

<script lang="ts" setup>
const props = defineProps({
	onSubmit: Function,
	disabledButton: Boolean,
	showBackButton: { type: Boolean, default: null },
	buttonTitle: { type: String, default: 'NEXT' },
});
</script>

<style scoped lang="css">
.wrapper {
	@apply flex flex-col container px-4 sm:px-0 sm:mx-auto h-full max-w-[1026px];
}

.container-content {
	@apply w-full bg-white p-6 sm:p-14 mb-2;
}

.sticky-div {
	position: sticky;
	bottom: 0;
	z-index: 1;
	background-color: white;
	box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}
</style>
